<template>
  <div class="broadcast" id="broadcast" :style="getDesignProperties('studioBackground')">
    <div :class="`xss-grid ${layoutName}`">
      <AppLogo
        v-for="logo in getActiveLogoList"
        class="brand-logo"
        :key="logo.url"
        :logo-id="logo.id"
        :src="logo.url"
        :redirectable="false"
        :style="logo.style"
        @dragStart="onLogoDragStart"
      />
      <div
        class="xss-grid-item"
        :class="{ draggable: isDraggable }"
        v-for="item in gridItems"
        :style="item.style"
        :key="item.id"
        :data-item-id="item.id"
        :data-screen="item.isScreen === 'true'"
        :draggable="isDraggable"
        @dragstart="onDragStart"
        @dragover="onDragOver"
        @dragend="onDragEnd"
        @drop="onDrop"
      >
        <VideoCard
          :id="`${item.type}-video-${item.id}-broadcast`"
          :class-name="`${item.type}`"
          :closed="item.videoMuted"
          :wrapper-class-name="`${item.bottomVideoCard ? `bottom-video-card` : ''}`"
          :mic-disabled="item.audioMuted"
          :user-name="item.displayName"
          :hide-username="hideLabels"
          :max-height="item.style.height"
          :display-name-style="getDesignProperties('userName')"
          :is-screen="item.isScreen === 'true'"
          :user="item"
        />
      </div>
      <AnimatedSubtitle />
    </div>
    <BroadcastBanner @show="onBannerShow" :style="`font-size: ${fontSize}px`" />
    <div v-if="getActiveOverlayId" class="broadcast-overlay" :style="getDesignProperties('studioOverlay')"></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { consoleLog } from 'xpermeet-lib';

import BroadcastBanner from './BroadcastBanner.vue';
import AnimatedSubtitle from '@/components/Animations/AnimatedSubtitle.vue';
import { SOLO, CROPPED } from '@/constants/layout';
import { STUDIO } from '@/constants/modules';
import { getItemPosition } from '@/helpers/layout';

export default {
  name: 'Broadcast',
  components: {
    BroadcastBanner,
    AnimatedSubtitle,
  },
  props: {
    jibri: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hideLabels: false,
      width: 0,
      height: 0,
      currentDragedItemId: null,
      fontSize: 18,
    };
  },
  computed: {
    ...mapState(STUDIO, ['roomConfig']),
    ...mapGetters(STUDIO, [
      'getUsers',
      'getDesignProperties',
      'getLayoutName',
      'getLayout',
      'getStagedUserIds',
      'findStagedUserById',
      'getStagedSize',
      'getIsMultiStaged',
      'stagedScreenShareIndex',
      'getIsScreenUserExists',
      'getActiveLogoList',
      'getActiveOverlay',
    ]),
    gridItems() {
      return [...this.getUsers].map((user) => {
        let style = {
          visibility: 'hidden',
        };
        if (this.getStagedUserIds.includes(user.id)) {
          const su = this.findStagedUserById(user.id);
          user.order = su.order;
          user.staged = true;
          style = getItemPosition({
            itemIndex: su.order,
            boxWidth: this.width,
            boxHeight: this.height,
            itemCount: this.getStagedSize,
            layout: this.layoutName,
            isScreen: user.isScreen === 'true',
            stagedScreenShareIndex: this.stagedScreenShareIndex,
          });
        }
        let { bottomVideoCard = false } = style;
        if (style.bottomVideoCard) {
          delete style.bottomVideoCard;
        }
        return {
          ...user,
          style,
          bottomVideoCard,
        };
      });
    },
    isDraggable() {
      return this.$can('changeLayout', 'Studio') && this.getLayout.draggable;
    },
    videoConstraints() {
      const selectedEndpoints = [];
      const onStageEndpoints = [];
      const constraints = {
        lastN: -1,
        colibriClass: 'ReceiverVideoConstraints',
        selectedEndpoints: selectedEndpoints,
        onStageEndpoints,
        defaultConstraints: { maxHeight: 180 },
        constraints: {},
      };

      this.gridItems.forEach((gi) => {
        if (gi.staged) {
          onStageEndpoints.push(gi.id);
          constraints.constraints[gi.id] = { maxHeight: 1080 };
        } else {
          selectedEndpoints.push(gi.id);
        }
      });

      return constraints;
    },
    layoutName() {
      if (this.getLayoutName !== SOLO.name && !this.getIsMultiStaged) {
        return SOLO.name;
      }

      if (!this.getIsScreenUserExists && this.getLayout.screen) {
        return CROPPED.name;
      }

      return this.getLayoutName;
    },
    getActiveOverlayId() {
      return this.getActiveOverlay?.id;
    },
  },
  mounted() {
    if (window.ResizeObserver) {
      const broadcastWrapper = document.getElementById('broadcast');

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          this.width = entry.contentRect.width;
          this.height = entry.contentRect.height;
          const min = this.width > this.height ? this.height : this.width;
          this.fontSize = min / 28;
          let animatedSubtitleHeight = this.height / 12;
          let animatedSubtitleScale = 1;
          if (animatedSubtitleHeight > 30) {
            animatedSubtitleScale = animatedSubtitleHeight / 30;
          }
          let cornerNotchWidth = this.width / 8;
          if (cornerNotchWidth < 150) {
            cornerNotchWidth = 150;
          }
          document.documentElement.style.setProperty('--v-animated-subtitle-scale', `${animatedSubtitleScale}`);
          document.documentElement.style.setProperty('--v-corner-notch-min-width', `${cornerNotchWidth}px`);
        }
      });

      resizeObserver.observe(broadcastWrapper);
    } else {
      consoleLog('Resize observer not supported!');
    }
  },
  methods: {
    ...mapActions('Studio', ['attachTrackToElement', 'swtichStagedUsersOrder', 'setReceiverConstraints']),
    ...mapMutations('Studio', ['REMOVE_USER_TRACK']),
    onBannerShow(show) {
      this.hideLabels = show;
    },
    onLogoDragStart() {
      this.currentDragedItemId = null;
    },
    onDragStart(e) {
      if (!e.srcElement.dataset.screen) {
        consoleLog('onDragStart', e.srcElement.dataset);
        this.currentDragedItemId = e.srcElement.dataset.itemId;
        e.srcElement.classList.add('drag');
        e.dataTransfer.effectAllowed = 'move';
      }
    },
    onDragEnd(e) {
      e.srcElement.classList.remove('drag');
    },
    onDragOver(e) {
      e.preventDefault();
      return false;
    },
    onDrop(e) {
      e.stopPropagation(); // stops the browser from redirecting.

      let itemId;
      if (e.currentTarget.dataset?.itemId && !e.currentTarget.dataset.screen) {
        itemId = e.currentTarget.dataset?.itemId;
      }

      if (this.currentDragedItemId && itemId && this.currentDragedItemId !== itemId) {
        this.swtichStagedUsersOrder([this.currentDragedItemId, itemId]);
      }

      return false;
    },
    setLastNandSelectParticipants() {
      if (!this.gridItems.length) return;

      this.$nextTick(() => {
        this.setReceiverConstraints(this.videoConstraints);
      });
    },
  },
  watch: {
    getStagedUserIds: 'setLastNandSelectParticipants',
  },
};
</script>

<style lang="scss">
.broadcast {
  position: relative;
  color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .brand-logo {
    z-index: 3;
    position: absolute;
    right: 0.7rem;
    top: 0.7rem;
  }
  .xss-grid {
    height: 100%;
    width: 100%;
    position: relative;

    .xss-grid-item {
      position: absolute;
      border: 2px solid transparent;
      transition: width 0.3s ease 0s, height 0.3s ease 0s, left 0.3s ease 0s, top 0.3s ease 0s, opacity 0.3s ease 0s;

      &.draggable {
        cursor: grab;
      }

      &.drag {
        border: 2px solid var(--v-theme-primary-base);
      }

      .video-card {
        &.hidden {
          .video-card--username {
            opacity: 0;
            transition: none !important;
          }
        }
      }
    }
  }
  .broadcast-overlay {
    position: absolute;
    top: 2px;
    left: 2px;
    background-size: contain;
    width: 98%;
    height: 98%;
  }
}
</style>

