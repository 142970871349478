var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "broadcast",
      style: _vm.getDesignProperties("studioBackground"),
      attrs: { id: "broadcast" },
    },
    [
      _c(
        "div",
        { class: `xss-grid ${_vm.layoutName}` },
        [
          _vm._l(_vm.getActiveLogoList, function (logo) {
            return _c("AppLogo", {
              key: logo.url,
              staticClass: "brand-logo",
              style: logo.style,
              attrs: { "logo-id": logo.id, src: logo.url, redirectable: false },
              on: { dragStart: _vm.onLogoDragStart },
            })
          }),
          _vm._l(_vm.gridItems, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "xss-grid-item",
                class: { draggable: _vm.isDraggable },
                style: item.style,
                attrs: {
                  "data-item-id": item.id,
                  "data-screen": item.isScreen === "true",
                  draggable: _vm.isDraggable,
                },
                on: {
                  dragstart: _vm.onDragStart,
                  dragover: _vm.onDragOver,
                  dragend: _vm.onDragEnd,
                  drop: _vm.onDrop,
                },
              },
              [
                _c("VideoCard", {
                  attrs: {
                    id: `${item.type}-video-${item.id}-broadcast`,
                    "class-name": `${item.type}`,
                    closed: item.videoMuted,
                    "wrapper-class-name": `${
                      item.bottomVideoCard ? `bottom-video-card` : ""
                    }`,
                    "mic-disabled": item.audioMuted,
                    "user-name": item.displayName,
                    "hide-username": _vm.hideLabels,
                    "max-height": item.style.height,
                    "display-name-style": _vm.getDesignProperties("userName"),
                    "is-screen": item.isScreen === "true",
                    user: item,
                  },
                }),
              ],
              1
            )
          }),
          _c("AnimatedSubtitle"),
        ],
        2
      ),
      _c("BroadcastBanner", {
        style: `font-size: ${_vm.fontSize}px`,
        on: { show: _vm.onBannerShow },
      }),
      _vm.getActiveOverlayId
        ? _c("div", {
            staticClass: "broadcast-overlay",
            style: _vm.getDesignProperties("studioOverlay"),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }