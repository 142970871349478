<template>
  <div class="d-flex align-center">{{ currentTime }}</div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'CurrentTime',
  props: {
    format: {
      type: String,
      default: 'HH:mm',
    },
  },
  data() {
    return {
      currentTime: dayjs().format(this.format),
    };
  },
  mounted() {
    this._updateInterval = setInterval(() => {
      this.currentTime = dayjs().format(this.format);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this._updateInterval);
  },
};
</script>

