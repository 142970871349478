var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" },
      ],
      staticClass: "audio-container",
    },
    [
      _vm.getLocalUser
        ? _c("audio", {
            staticClass: "local-audio",
            attrs: { muted: "", id: `local-audio-${_vm.getLocalUser.id}` },
          })
        : _vm._e(),
      _vm._l(_vm.getRemoteUserIds, function (userId) {
        return _c("div", { key: userId }, [
          _c("audio", {
            staticClass: "remote-audio",
            attrs: { autoplay: "", id: `remote-audio-${userId}` },
          }),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }