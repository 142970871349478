import store from '@/store';
import { STUDIO } from '@/constants/modules';
import { UPDATE_USER } from '@/constants/mutation-types';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

export function clearStudioName(confName) {
  return decodeURI(confName).toLowerCase().replace(/ /g, '');
}

export function attachTrackToElements(users, audio = false, video = false) {
  users.forEach((user) => {
    let userTracks = [];
    if (user.type === 'local') {
      userTracks = xperMeetLib.conference.localUser.tracks;
    } else {
      userTracks = xperMeetLib.conference.localUser.conferenceInstance.room.participants[user.id].getTracks();
    }

    const audioTrackCount = userTracks.filter((track) => track.getType() === 'audio').length;
    const videoTrackCount = userTracks.filter((track) => track.getType() === 'video').length;

    if (audioTrackCount === 0) {
      store.commitWithModule(STUDIO, UPDATE_USER, { userId: user.id, data: { audioMuted: true } });
    }
    if (videoTrackCount === 0) {
      store.commitWithModule(STUDIO, UPDATE_USER, { userId: user.id, data: { videoMuted: true } });
    }

    userTracks.forEach((track) => {
      if ((audio && track.getType() === 'audio') || (video && track.getType() === 'video')) {
        const container = document.getElementById(`${user.type}-${track.getType()}-${user.id}`);
        xperMeetLib.conference.attachTrackToElement(container, user.type, user.id, track);
      }

      // Set initial mute state when remote track added
      const property = track.getType() === 'video' ? 'videoMuted' : 'audioMuted';
      store.commitWithModule(STUDIO, UPDATE_USER, { userId: user.id, data: { [property]: track.isMuted() } });
    });
  });
}

export function detachUserVideoTracks(userId, isLocalUser) {
  if (isLocalUser) {
    const track = xperMeetLib.conference.localUser.conferenceInstance.room.getLocalVideoTrack();
    if (track) {
      track.containers.forEach((container) => {
        track.detach(container);
      });
    }
  } else {
    const participant = xperMeetLib.conference.localUser.conferenceInstance.room.getParticipantById(userId);
    if (participant) {
      const videoTracks = participant.getTracksByMediaType('video');
      videoTracks.forEach((track) => {
        track.containers.forEach((container) => {
          track.detach(container);
        });
      });
    }
  }
}

