<template>
  <div class="corner-notch">
    <div class="corner-notch--container">
      <ul class="corner-notch--container--list">
        <li
          v-for="(item, key) in cornerNotchTexts"
          class="corner-notch--container--list--item d-flex align-center justify-center"
          :key="key"
          :class="getCornerNotchFontSize(item)"
        >
          <div class="d-flex align-center justify-center px-2">
            <AppIcon v-if="item.type !== 'textOnly'" class="ml-3 mr-1" :icon="item.icon" color="var(--v-white-base)" width="16" />
            <div v-if="item.type !== 'time'" class="px-2">{{ item.value }}</div>
            <CurrentTime v-else />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { STUDIO } from '@/constants/modules';
import CurrentTime from '@/components/Common/CurrentTime.vue';

const perItemDownTime = 1.5;
const perItemAnimationTime = 0.3;

export default {
  name: 'CornerNotch',
  components: { CurrentTime },
  computed: {
    ...mapGetters(STUDIO, ['animatedSubtitle']),
    getCornerNotchFontSize() {
      return (item) => {
        if (item.value.length <= 8) {
          return 'corner-notch-size-6';
        } else if (item.value.length <= 14) {
          return 'corner-notch-size-5';
        }
        if (item.value.length <= 18) {
          return 'corner-notch-size-4';
        }
        if (item.value.length <= 22) {
          return 'corner-notch-size-3';
        }
        if (item.value.length <= 26) {
          return 'corner-notch-size-2';
        }
        if (item.value.length <= 30) {
          return 'corner-notch-size-1';
        }
      };
    },
    cornerNotchTexts() {
      return this.animatedSubtitle?.cornerNotchProperties;
    },
    cornerNotchLength() {
      return this.cornerNotchTexts.length;
    },
    itemsTotalAnimationTime() {
      return (perItemDownTime + 2 * perItemAnimationTime) * this.cornerNotchLength;
    },
    returnAnimationTime() {
      return (this.cornerNotchLength * perItemDownTime) / 3;
    },
    animationTime() {
      return this.itemsTotalAnimationTime + this.returnAnimationTime;
    },
    perItemDownTimePercentage() {
      return (100 * perItemDownTime) / this.animationTime;
    },
    perItemAnimationTimePercentage() {
      return (100 * perItemAnimationTime) / this.animationTime;
    },
    percent() {
      return 100 / this.cornerNotchLength;
    },
    animationFrameText() {
      let text = ' @keyframes change{';
      for (let i in this.cornerNotchTexts) {
        const keyNumber = Number(i);
        const dividedNumber = keyNumber * this.perItemDownTimePercentage + keyNumber * this.perItemAnimationTimePercentage * 3;
        const nextDividedNumber = dividedNumber + this.perItemDownTimePercentage + this.perItemAnimationTimePercentage;
        const percentageNumber = keyNumber * this.percent;
        let firstPercantage = percentageNumber + this.perItemAnimationTimePercentage;
        let lastPercantage = percentageNumber + this.perItemAnimationTimePercentage;
        let firstPercantageString;
        let secondPercantageString = `translateY(-${percentageNumber}%)`;
        let lastPercantageString;
        if (keyNumber === 0) {
          firstPercantageString = `translateY(${Math.abs(firstPercantage)}%)`;
          lastPercantageString = `translateY(${Math.abs(lastPercantage)}%)`;
        } else if (keyNumber === this.cornerNotchLength - 1) {
          firstPercantageString = `translateY(-${firstPercantage}%)`;
          lastPercantageString = `translateY(-${lastPercantage}%)`;
        } else {
          firstPercantageString = `translateY(-${firstPercantage}%)`;
          lastPercantageString = `translateY(-${Math.abs(percentageNumber - this.perItemAnimationTimePercentage)}%)`;
        }

        text += `
            ${dividedNumber}%{transform: ${firstPercantageString};}
            ${dividedNumber + this.perItemAnimationTimePercentage}%,${nextDividedNumber}%{transform:${secondPercantageString};}
            ${nextDividedNumber + this.perItemAnimationTimePercentage}%{transform:${lastPercantageString};}`;
        if (keyNumber === this.cornerNotchLength - 1) {
          const lastPercentageNumber =
            nextDividedNumber + this.perItemAnimationTimePercentage + 2 > 98 ? nextDividedNumber + this.perItemAnimationTimePercentage + 0.5 : 98;
          text += `${lastPercentageNumber}%{transform: translate3d(0, %5, 0);}
             }
         .corner-notch--container--list {animation: change ${this.animationTime}s linear infinite;}`;
        }
      }
      return text;
    },
    scaleFrameText() {
      let text = ' @keyframes scaleAnimation{';
      for (let i in this.cornerNotchTexts) {
        const keyNumber = Number(i);
        const dividedNumber = keyNumber * this.perItemDownTimePercentage + keyNumber * this.perItemAnimationTimePercentage * 3;
        text += `
          ${dividedNumber - this.perItemAnimationTimePercentage}%{transform: scale(1);}
          ${dividedNumber}%{transform: scale(0.9);}
          ${dividedNumber + this.perItemAnimationTimePercentage}%{transform: scale(1);}
          `;
        if (keyNumber === this.cornerNotchLength - 1) {
          text += `}
      .corner-notch--container {
              animation: scaleAnimation ${this.animationTime}s linear infinite;
            }
            `;
        }
      }
      return text;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.appendFrame();
    });
  },
  methods: {
    appendFrame() {
      const container = document.querySelector('.corner-notch--container');
      const list = document.querySelector('.corner-notch--container--list');
      const listStyle = list.getElementsByTagName('style');
      const containerStyle = container.getElementsByTagName('style');
      if (this.cornerNotchLength > 1) {
        if (!listStyle?.[0]) {
          const scaleKeyFrames = document.createElement('style');
          scaleKeyFrames.innerHTML = this.scaleFrameText;
          container.appendChild(scaleKeyFrames);

          const keyFrames = document.createElement('style');
          keyFrames.innerHTML = this.animationFrameText;
          list.appendChild(keyFrames);
        } else {
          let cStyle = containerStyle[0].innerHTML.includes('scaleAnimation') ? containerStyle[0] : containerStyle[1];
          cStyle.innerHTML = this.scaleFrameText;
          listStyle[0].innerHTML = this.animationFrameText;
        }
      } else {
        if (listStyle?.[0]) {
          let cStyle = containerStyle[0].innerHTML.includes('scaleAnimation') ? containerStyle[0] : containerStyle[1];
          listStyle[0].innerHTML = '';
          cStyle.innerHTML = '';
        }
      }
    },
  },
  watch: {
    cornerNotchLength() {
      if (this.cornerNotchLength) {
        this.appendFrame();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.corner-notch {
  overflow: hidden;
  color: var(--v-white-base);
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  &::after {
    content: '';
    height: calc(20px * var(--v-animated-subtitle-scale));
    width: calc(3px * var(--v-animated-subtitle-scale));
    background: var(--v-white-base);
    position: absolute;
    right: 1px;
    top: calc(5px * var(--v-animated-subtitle-scale));
  }
  &--container {
    font-weight: 600;
    overflow: hidden;
    height: calc(20px * var(--v-animated-subtitle-scale));
    &--list {
      margin-top: 0;
      padding-left: 0px;
      text-align: left;
      list-style: none;

      &--item {
        line-height: 30px;
        margin: 0;
        text-align: center;
        min-width: var(--v-corner-notch-min-width);
        height: calc(20px * var(--v-animated-subtitle-scale));
        &.corner-notch-size-1 {
          font-size: calc(11px * var(--v-animated-subtitle-scale));
        }
        &.corner-notch-size-2 {
          font-size: calc(11px * var(--v-animated-subtitle-scale));
        }
        &.corner-notch-size-3 {
          font-size: calc(11px * var(--v-animated-subtitle-scale));
        }
        &.corner-notch-size-4 {
          font-size: calc(12px * var(--v-animated-subtitle-scale));
        }
        &.corner-notch-size-5 {
          font-size: calc(13px * var(--v-animated-subtitle-scale));
        }
        &.corner-notch-size-6 {
          font-size: calc(14px * var(--v-animated-subtitle-scale));
        }
      }
    }
  }
}
</style>

