<template>
  <div v-show="false" class="audio-container">
    <audio v-if="getLocalUser" muted class="local-audio" :id="`local-audio-${getLocalUser.id}`" />
    <div v-for="userId in getRemoteUserIds" :key="userId">
      <audio autoplay class="remote-audio" :id="`remote-audio-${userId}`" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { STUDIO } from '@/constants/modules';
import { isInStagedUsers } from '@/helpers/staged-users';

export default {
  name: 'UsersAudioContainer',
  props: {
    jibri: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(STUDIO, ['getLocalUser', 'getRemoteUserIds', 'getStagedUserIds']),
    ...mapState(STUDIO, ['roomConfig']),
  },
  mounted() {
    setTimeout(() => {
      this.muteUnsatedUsers();
    }, 2000);
  },
  methods: {
    muteUnsatedUsers() {
      if (this.jibri) {
        this.getRemoteUserIds.forEach((id) => {
          const el = document.getElementById(`remote-audio-${id}`);
          if (el) {
            el.muted = !isInStagedUsers(id);
          }
        });
      }
    },
  },
  watch: {
    getStagedUserIds: 'muteUnsatedUsers',
  },
};
</script>

