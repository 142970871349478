<template>
  <div class="broadcast-banner px-2 py-2" :class="{ show: show, 'with-animated': isAnimatedSubtitleActive }" :style="getDesignProperties('banner')">
    <div class="broadcast-banner--text d-flex align-center">
      <DestinationAvatar
        class="message-avatar mr-2"
        v-if="message"
        size="50"
        :avatar="message.senderAvatar"
        :destination-types="message.selectedSessionPayload || message.streamType | getUniques"
        :icon-position="message.incoming ? 'right' : ''"
      />
      <div class="broadcast-banner--current-text">
        <div class="broadcast-banner--banner-text pb-2" v-if="bannerSender">{{ bannerSender }}</div>
        <span>{{ currentText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { STUDIO } from '@/constants/modules';
import { consoleLog } from 'xpermeet-lib';

import DestinationAvatar from '@/components/DestinationAvatar';
import bannerQueue from '@/helpers/banner';

export default {
  name: 'BroadcastBanner',
  components: {
    DestinationAvatar,
  },
  data() {
    return {
      currentText: null,
      message: null,
      show: false,
      bannerSender: null,
    };
  },
  computed: {
    ...mapState(STUDIO, ['banners']),
    ...mapGetters(STUDIO, ['getDesignProperties', 'isAnimatedSubtitleActive']),
  },
  created() {
    bannerQueue.addCallback('show', this.showBanner.bind(this));
    bannerQueue.addCallback('hide', this.hideBanner.bind(this));
  },
  methods: {
    showBanner(item) {
      consoleLog('Banner Show: ', item);
      this.currentText = item.text;
      this.bannerSender = item?.sender;
      if (item.senderAvatar) {
        this.message = item;
      }
      this.show = true;
    },
    hideBanner(item) {
      consoleLog('Banner Hide: ', item);
      this.show = false;
      this.message = null;
      this.bannerSender = null;
    },
  },
  watch: {
    show() {
      this.$emit('show', this.show);
    },
  },
};
</script>

<style lang="scss">
.broadcast-banner {
  background: rgba(38, 37, 37, 0.67);
  position: absolute;
  bottom: 1rem;
  left: -3rem;
  border-left: 20px solid var(--v-theme-primary-base);
  max-width: 90%;
  transition: left 0.4s, opacity 0.2s;
  opacity: 0;
  font-size: 24px;
  z-index: 0;
  &.with-animated {
    bottom: calc((var(--v-animated-subtitle-scale) * 30px) + 1rem);
  }

  &--text {
    position: relative;
    display: inline-block;
  }

  &--current-text {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%;
    max-height: 10.3em;
  }

  &--banner-text {
    font-size: 21px;
    font-weight: 600;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  &.show {
    visibility: visible;
    left: 1rem;
    opacity: 1;
    z-index: 2;
  }
}
</style>

