<template>
  <div v-if="isAnimatedSubtitleActive" class="animated-subtitle-wrapper">
    <CornerNotch v-if="cornerNotchActive" />
    <div class="animated-subtitle">
      <span class="animated-subtitle--text">
        {{ animatedSubtitleText }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { STUDIO } from '@/constants/modules';
import CornerNotch from '@/components/Animations/CornerNotch';

export default {
  name: 'AnimatedSubtitle',
  components: { CornerNotch },
  computed: {
    ...mapState(STUDIO, ['roomConfig', 'design']),
    ...mapGetters(STUDIO, ['getDesign', 'isAnimatedSubtitleActive']),
    animatedSubtitle() {
      return this.getDesign?.animatedElements?.animatedSubtitle;
    },
    cornerNotchActive() {
      return this.getDesign?.animatedElements?.animatedSubtitle?.cornerNotch;
    },
    animatedSubtitleText() {
      return this.animatedSubtitle?.text;
    },
    animatedSubtitleTextSpeed() {
      return this.animatedSubtitle?.textSpeed;
    },
  },
  methods: {
    changeAnimationTime() {
      this.$nextTick(() => {
        const animatedSubtitle = document.querySelector('.animated-subtitle');
        const animatedSubtitleText = document.querySelector('.animated-subtitle--text');
        const calculated = animatedSubtitle.offsetWidth / animatedSubtitleText.offsetWidth;
        const animateSecond = (1 / (calculated * this.animatedSubtitleTextSpeed)) * 6;
        document.documentElement.style.setProperty('--v-animate-second', `${animateSecond}s`);
      });
    },
  },
  watch: {
    animatedSubtitle() {
      if (this.animatedSubtitleText && this.isAnimatedSubtitleActive) {
        this.changeAnimationTime();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.animated-subtitle-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--v-black-40);
  height: calc(var(--v-animated-subtitle-scale) * 30px);
  z-index: 2;

  .animated-subtitle {
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    &--text {
      font-weight: 500;
      font-size: calc(20px * var(--v-animated-subtitle-scale));
      padding-left: 100%;
      display: inline-block;
      animation: rightToLeft var(--v-animate-second) linear infinite;
    }
  }
}
@keyframes rightToLeft {
  100% {
    transform: translate(-100%, 0);
  }
}
</style>

