var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "broadcast-banner px-2 py-2",
      class: { show: _vm.show, "with-animated": _vm.isAnimatedSubtitleActive },
      style: _vm.getDesignProperties("banner"),
    },
    [
      _c(
        "div",
        { staticClass: "broadcast-banner--text d-flex align-center" },
        [
          _vm.message
            ? _c("DestinationAvatar", {
                staticClass: "message-avatar mr-2",
                attrs: {
                  size: "50",
                  avatar: _vm.message.senderAvatar,
                  "destination-types": _vm._f("getUniques")(
                    _vm.message.selectedSessionPayload || _vm.message.streamType
                  ),
                  "icon-position": _vm.message.incoming ? "right" : "",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "broadcast-banner--current-text" }, [
            _vm.bannerSender
              ? _c(
                  "div",
                  { staticClass: "broadcast-banner--banner-text pb-2" },
                  [_vm._v(_vm._s(_vm.bannerSender))]
                )
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.currentText))]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }