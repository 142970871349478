var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAnimatedSubtitleActive
    ? _c(
        "div",
        { staticClass: "animated-subtitle-wrapper" },
        [
          _vm.cornerNotchActive ? _c("CornerNotch") : _vm._e(),
          _c("div", { staticClass: "animated-subtitle" }, [
            _c("span", { staticClass: "animated-subtitle--text" }, [
              _vm._v(" " + _vm._s(_vm.animatedSubtitleText) + " "),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }