var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "corner-notch" }, [
    _c("div", { staticClass: "corner-notch--container" }, [
      _c(
        "ul",
        { staticClass: "corner-notch--container--list" },
        _vm._l(_vm.cornerNotchTexts, function (item, key) {
          return _c(
            "li",
            {
              key: key,
              staticClass:
                "corner-notch--container--list--item d-flex align-center justify-center",
              class: _vm.getCornerNotchFontSize(item),
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center justify-center px-2" },
                [
                  item.type !== "textOnly"
                    ? _c("AppIcon", {
                        staticClass: "ml-3 mr-1",
                        attrs: {
                          icon: item.icon,
                          color: "var(--v-white-base)",
                          width: "16",
                        },
                      })
                    : _vm._e(),
                  item.type !== "time"
                    ? _c("div", { staticClass: "px-2" }, [
                        _vm._v(_vm._s(item.value)),
                      ])
                    : _c("CurrentTime"),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }